:root {
  --bs-body-color: #fff;
}

body {
  background-color: #282c34 !important;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #fff !important;
}

.bot-container{
  min-width: 100%;
  background-color: #282c34;
}
.my-4 list-group{
  min-width: 100% !important;
  text-align: center;
}
.top .list-group-item {
  text-align: center;
}


.top .list-group-item button{
  display: inline-block;
  margin: 5px;
}
.my-4{
  background: white;
}

label.form-label {
  background: white;
  color: black;
}

.modal-title.h4 {
  color: black;
}

.box{
  margin: 10px auto;
}

.list-group-item{
  background: transparent !important;
}

button {
  font-size: 11px !important;
}

.exit-button{
  display: block;
  margin: 0 auto;
}

span.config-name {
  width: 100px;
  display: inline-block;
  float: left;
  text-align: center;
  font-size: 20px;
  /* background: green; */
}

.config-row {
  clear: both;
  padding: 3px;
  height: auto;
}


.gray {
  color: #929292;
}

span.positions {border: solid 1px #efefef;width: 300px;display: inline-block;float: left;/* height: 27px; */}

span.config-date {
  display: inline-block;
  /* float: left; */
  padding: 0px 10px;
  float: left;
  border: solid 1px #efefef;
}

.bot-configs{
  width: 95%;
    margin: 10px auto;
    text-align: center;
    border: solid 1px #bcb9b9;
    padding: 20px;
}

.config-name span.badge {
  position: absolute;
  left: 0px;
}

@media (min-width: 320px) {
  #root > div {
    padding: 0 !important;
  }
}

.form-check-label {
  color: black;
}

.slider-legend {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
